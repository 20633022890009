<template lang="pug">
div(v-if="message" class="bg-successgreen p-4 text-center")
    p.text-lg {{ message }}
</template>

<script setup>
const msgState = useState('systemMessage')
const message = msgState.value?.txt

onMounted(() => {
  if (message) {
    msgState.value.txt = ''
  }
})
</script>
